<template>
  <v-card>
    <div class="text-center">
      <v-icon :icon="props.icon" color="accent" size="64" role="presentation" />
    </div>
    <div
      class="text-center text-body-1 text-medium-emphasis px-3 pb-3"
      style="line-height: 1.5em"
    >
      {{ props.text }}
      <a
        v-if="href"
        :href="href"
        class="text-decoration-underline text-primary"
        tabindex="0"
        >{{ props.hrefText }}.</a
      >
    </div>
  </v-card>
</template>

<script setup lang="ts">
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  href: {
    type: String,
    required: false,
    default: "",
  },
  hrefText: {
    type: String,
    required: false,
    default: "",
  },
});
</script>
